import { Version } from './version.model';

export const version: Version = {
  build: {
    time: '2025-03-26T15:44:31+0000',
  },
  git: {
    branchName: 'master',
    commitHash: 'f5633ec3',
  },
};
